import Vue from 'vue'

import "reset-css"  // 清除默认样式
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'less';
import '@/assets/icon/index'
import '@/utils/filter'
import dataV from '@jiaminghi/data-view'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(dataV);

new Vue({
  router,
  el: '#app',
  store,
  render: h => h(App)
}).$mount('#app')
