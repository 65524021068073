import instance from "./request"


// 登录请求
export const LoginApi = (params) => instance.post("/admin/v1/login",params);

// 退出请求
export const LogoutApi = (params) => instance.get("/admin/v1/logout",{params});

// 获取登录账号的菜单栏目
export const GetUserRoutersApi = () => instance.get("/admin/v1/menubar");

// 获取登录账号的用户信息
export const GetUserInfoApi = () => instance.get("/admin/v1/userInfo");

// 管理员列表模块
export const GetAdminListApi = (params) => instance.get("/admin/v1/adminList",{params});    // 获取登录账号的用户信息
export const AdminSaveApi = (params) => instance.post("/admin/v1/adminSave",params);    // 新增登录账号的信息
export const GetAdminInfoApi = (params) => instance.get("/admin/v1/adminInfo",{params});    // 获取单个管理员详细信息
export const AdminUpdateApi = (params) => instance.post("/admin/v1/adminUpdate",params);    // 编辑管理员信息
export const AdminDeleteApi = (params) => instance.post("/admin/v1/adminDelete",params);    // 删除管理员信息
export const UserAdminUpdateApi = (params) => instance.post("/admin/v1/userAdminUpdate",params);    // 用户自行更新管理账号信息

// 跟进人列表模块
export const GetFupPersonListApi = (params) => instance.get("/admin/v1/fupPersonList",{params});    // 获取跟进人列表
export const FupPersonSaveApi = (params) => instance.post("/admin/v1/fupPersonSave",params);    // 新增跟进人信息
export const GetFupPersonApi = (params) => instance.get("/admin/v1/fupPersonInfo",{params});    // 获取跟进人详情
export const FupPersonUpdateApi = (params) => instance.post("/admin/v1/fupPersonUpdate",params);    // 编辑跟进人信息
export const FupPersonDeleteApi = (params) => instance.post("/admin/v1/fupPersonDelete",params);    // 删除跟进人信息

// 账号类型权限模块
export const GetAdminRolePowerListApi = (params) => instance.get("/admin/v1/adminRolePowerList",{params});    // 账号类型权限列表

// 管理员登录日志模块
export const GetAdminLogListApi = (params) => instance.get("/admin/v1/adminLogList",{params});    // 管理员登录日志列表

// 会员列表模块
export const GetMemberListApi = (params) => instance.get("/admin/v1/memberList",{params});    // 获取会员列表
export const MemberSaveApi = (params) => instance.post("/admin/v1/memberSave",params);        // 新增会员账号
export const GetMemberInfoApi = (params) => instance.get("/admin/v1/memberInfo",{params});    // 获取会员详情
export const MemberUpdateApi = (params) => instance.post("/admin/v1/memberUpdate",params);    // 编辑会员信息
export const MemberDeleteApi = (params) => instance.post("/admin/v1/memberDelete",params);    // 删除会员信息

// 特装管理模块
export const GetExhibitorCompanyListApi = (params) => instance.get("/admin/v1/exhibitorCompany",{params});    // 获取企业信息列表
export const GetExhibitorEntranceListApi = (params) => instance.get("/admin/v1/exhibitorEntrance",{params});    // 获取进馆资料列表
export const ExhibitorEntranceUpdateApi = (params) => instance.post("/admin/v1/exhibitorEntranceUpdate",params);    // 编辑进馆资料
export const GetExhibitorBacklistListApi = (params) => instance.get("/admin/v1/exhibitorBacklist",{params});    // 获取会刊资料列表
export const GetExhibitorProductListApi = (params) => instance.get("/admin/v1/exhibitorProduct",{params});    // 获取参展展品列表
export const GetExhibitorOrderlistApi = (params) => instance.get("/admin/v1/exhibitorOrderlist",{params});    // 获取增值服务订单列表

// 搭建商管理模块
export const GetBuilderCompanyListApi = (params) => instance.get("/admin/v1/builderCompany",{params});    // 获取企业信息列表
export const GetBuilderEntranceListApi = (params) => instance.get("/admin/v1/builderEntrance",{params});    // 获取进馆资料列表
export const BuilderEntranceUpdateApi = (params) => instance.post("/admin/v1/builderEntranceUpdate",params);    // 编辑进馆资料

export const GetBuilderReviewListApi = (params) => instance.get("/admin/v1/builderReview",{params});    // 获取获取特装审图列表
export const BuilderReviewEditApi = (params) => instance.get("/admin/v1/builderReviewEdit",{params});    // 获取特装审图编辑数据
export const BuilderReviewUpdateApi = (params) => instance.post("/admin/v1/builderReviewUpdate",params);    // 特装审图编辑保存操作
export const QuillUploadFileApi = (params) => instance.post("/admin/v1/quillUploadFile",params);    // 富文本编辑器图片上传

export const GetBuilderOrderDepositListApi = (params) => instance.get("/admin/v1/builderOrderDeposit",{params});    // 获取展位押金列表

export const GetBuilderOrderFixedFeeApi = (params) => instance.get("/admin/v1/builderOrderFixedFee",{params});    // 获取综合费用列表
export const GetBuilderOrderFormListApi = (params) => instance.get("/admin/v1/builderOrderForm",{params});    // 获取服务订单列表
export const GetBuilderOrderFormEditApi = (params) => instance.get("/admin/v1/builderOrderFormEdit",{params});    // 获取服务订单列表
export const BuilderOrderFormUpdateApi = (params) => instance.post("/admin/v1/builderOrderFormUpdate",params);    // 服务订单更新操作

export const GetBuilderConstructionListApi = (params) => instance.get("/admin/v1/builderConstruction",{params});    // 获取施工证件列表
export const GetInvoiceListApi = (params) => instance.get("/admin/v1/invoiceList",{params});    // 获取发票申请列表
export const InvoiceListUpdateApi = (params) => instance.post("/admin/v1/invoiceUpdate",params);    // 发票申请编辑保存操作
export const GetInvoiceConstructionListApi = (params) => instance.get("/admin/v1/invoiceConstruction",{params});    // 获取施工证发票列表
export const InvoiceConstructionUpdateApi = (params) => instance.post("/admin/v1/invoiceConstructionUpdate",params);    // 施工证发票编辑保存操作
export const GetEarnestMoneyListApi = (params) => instance.get("/admin/v1/earnestMoney",{params});    // 获取押金退还申请列表
export const GetInvoiceAllowApi = (params) => instance.get("/admin/v1/invoiceAllow",{params});    // 获取获取综合费允许发票列表
export const GetInvoiceSyntheticalExcelApi = (params) => instance.get("/admin/v1/invoiceSyntheticalExcel",{params});    // 下载excel，发票申请表
export const GetEarnestMoneyExcelApi = (params) => instance.get("/admin/v1/earnestMoneyExcel",{params});    // 下载excel，押金退还申请列表



// 财务人员管理模块
export const GetExhibitorFeeVolumeFeeListApi = (params) => instance.get("/admin/v1/exhibitorFeeVolumeFee",{params});    // 获取音量保证金列表
export const ExhibitorFeeVolumeFeeUpdateApi = (params) => instance.post("/admin/v1/exhibitorFeeVolumeFeeUpdate",params);    // 财务编辑音量保证金收款登记
export const GetExhibitorFeeIncrementFeeListApi = (params) => instance.get("/admin/v1/exhibitorFeeIncrementFee",{params});    // 获取增值服务订单列表
export const ExhibitorFeeIncrementFeeUpdateApi = (params) => instance.post("/admin/v1/exhibitorFeeIncrementFeeUpdate",params);    // 财务编辑增值服务订单收款登记

export const GetBuilderFeeDepositFeeListApi = (params) => instance.get("/admin/v1/builderFeeDepositFee",{params});    // 获取搭建商展位押金列表
export const BuilderFeeDepositFeeUpdateApi = (params) => instance.post("/admin/v1/builderFeeDepositFeeUpdate",params);    // 财务编辑搭建商展位押金收款登记

export const GetBuilderFeeSyntheticalFeeListApi = (params) => instance.get("/admin/v1/builderFeeSyntheticalFee",{params});    // 获取搭建商综合费用订单列表
export const BuilderFeeSyntheticalFeeUpdateApi = (params) => instance.post("/admin/v1/builderFeeSyntheticalFeeUpdate",params);    // 财务编辑搭建商综合费用订单收款登记

export const GetBuilderFeeServeFeeListApi = (params) => instance.get("/admin/v1/builderFeeServeFee",{params});    // 获取搭建商服务订单列表
export const GetBuilderFeeServeFeeEditApi = (params) => instance.get("/admin/v1/builderFeeServeFeeEdit",{params});    // 获取搭建商服务订单列表
export const BuilderFeeServeFeeUpdateApi = (params) => instance.post("/admin/v1/builderFeeServeFeeUpdate",params);    // 财务编辑搭建商服务订单收款登记

export const GetCustomerListApi = (params) => instance.get("/admin/v1/customerList",{params});    // 获取会员列表

export const GetFinancialCostApi = (params) => instance.get("/admin/v1/financialCost",{params});    // 财务管理账单统计
export const GetFinancialListApi = (params) => instance.get("/admin/v1/financialList",{params});    // 财务管理搜索列表
export const GetFinancialUpdateApi = (params) => instance.post("/admin/v1/financialUpdate",params);    // 财务管理搜索更新操作
export const GetFinancialExcelApi = (params) => instance.get("/admin/v1/financialExcel",{params});    // 下载excel，财务管理订单总表
export const GetFinancialOrderItemExcelApi = (params) => instance.get("/admin/v1/financialOrderItemExcel",{params});    // 下载excel，财务管理订单总表

