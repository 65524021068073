import {GetUserInfoApi} from "@/request/api"

export default{
    namespaced: true,
    state: {
        // 用户信息，localStorage 本地存储
        userInfo:JSON.parse(localStorage.getItem("expo-userInfo")) || {
            admin_id:null,
            role_id:null,
            mobile:null,
            nickname:null
        }
    },
    mutations: {
        changeUserInfo(state,payload){
            state.userInfo = payload;
            localStorage.setItem("expo-userInfo",JSON.stringify(state.userInfo))
        }
    },
    actions: {
        async asyncChangeUserInfo({commit}){
            let GetUserInfoApiRes = await GetUserInfoApi();
            
            if(!GetUserInfoApiRes)return;
            commit("changeUserInfo",{
                admin_id:GetUserInfoApiRes.adminInfo.admin_id,
                role_id:GetUserInfoApiRes.adminInfo.role_id,
                mobile:GetUserInfoApiRes.adminInfo.mobile,
                nickname:GetUserInfoApiRes.adminInfo.nickname
            })
        }
    }
}