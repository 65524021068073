// 获取菜单栏是否则折叠

export default{
    namespaced: true,
    state: {
        isCollapse:false
    },
    mutations: {
        changeIsCollapse(state){
            // 取反
            state.isCollapse=!state.isCollapse
        }
    }
}