import axios from "axios";
import { Message } from "element-ui";
import router from "@/router"

const instance = axios.create({
    baseURL:window.baseUrl,
    // baseURL:"https://expoapi2024.uniceramics.com.cn",
    timeout:"15000",
});

instance.interceptors.request.use(config=>{
    const token = localStorage.getItem("expo-authorization-token");
    if(token && !config.url.endsWith("/login")){
        config.headers["Authorization"] = "Bearer " + token;
    }
    return config
},err=>{
    return Promise.reject(err)
});

instance.interceptors.response.use(res=>{
    let res_data = res.data;
    // 导出文件的时候，后端没有给我们返回code属性，直接是二进制字符串，但是数据是没有问题的
    // 所以要让res_data到组件中去处理，不能在这里拦截死，所以要加res_data.code &&
    if(res_data.errcode && res_data.errcode !== 0){
        Message.error(res_data.errmsg || "网络请求错误");

        if(res_data.errcode == 40001){
            // 40001 一般表示token过期或者没有带token
            localStorage.removeItem("expo-authorization-token")
            router.push("/login")
        } else if(res_data.errcode == 40004) {
            router.push("/builder/review")
        }

        // 这里return不是为了结束函数，实际上是把return值传到组件中的res
        return false
    } 
    return res_data
    
},err=>{
    return Promise.reject(err)
});

export default instance