import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import { GetUserRoutersApi } from "@/request/api"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mainlayout',
    component: () => import(/* webpackChunkName: "mainlayout" */ '../views/layout/MainLayout.vue'),
    redirect: "/home",
    children:[]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue')
  },
  {
    path:'*',
    component:()=>import(/* webpackChunkName: "404" */ '../views/404View.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to,from,next)=>{
  
  const token = localStorage.getItem("expo-authorization-token")

  // 如果用户访问登录页面，但是token已存在，跳转到首页
  if(to.path === "/login" && token){
    next("/home");
    return
  }
  // 如果用户访问不是登录页面，但是没有token，跳转到登录页面
  if(to.path !== "/login" && !token){
    next("/login")
    return
  }

  // 获取用户的菜单数据
  if(token && store.state.userMenuData.menuData.length==0){
    let GetUserRoutersApiRes = await GetUserRoutersApi();
    if(!GetUserRoutersApiRes)return;

    let newUserMenuData = [{title:"首页",icon:"home",path:"/home"}];
    let ret = GetUserRoutersApiRes.menuBarList.map(item=>{
      if(item.children){
        return {
          title: item.title,
          icon: item.icon,
          path: item.path,
          children:item.children.map(sitem=>{
            return {
              title:sitem.title,
              path:item.path + "/" + sitem.path
            }
          })
        }
      } else {
        return {
          title:item.meta.title,
          icon:item.meta.icon,
          path:item.path
        }
      }
    })
    newUserMenuData = [...newUserMenuData, ...ret];
    store.commit("userMenuData/changeMenuData",newUserMenuData);
    // 以上生成菜单数据

    // 以下生成用户可以访问的路由数据
    let newChildrenRoutes = [{
        path:"/home",
        meta:{title:["首页"]},
        component:()=>import('../views/HomeView.vue')
      },{
        path:"/profile",
        meta:{title:["个人中心"]},
        component:()=>import('../views/ProfileView.vue')
      },{
        path:"/exhibitor/product",
        meta:{title:["参展展品列表"]},
        component:()=>import('../views/exhibitor/ExhibitorProductView.vue')
      },{
        path:"/builder/reviewEdit",
        meta:{title:["审图详情页"]},
        component:()=>import('../views/builder/BuilderReviewEditView.vue')
      }];
    GetUserRoutersApiRes.menuBarList.forEach(item=>{

      let ret = item.children.map(sitem=>{
        return {
          path:item.path+"/"+sitem.path,
          component:()=>import(`../views${item.path}/${sitem.name}View.vue`),
          meta:{
            title:[item.title,sitem.title]
          }
        }
      });
      newChildrenRoutes = [...newChildrenRoutes,...ret];
    });

    // 把生成好的数组添加到路由里面的children，做为子路由
    newChildrenRoutes.forEach(item=>{
      router.addRoute("mainlayout",item);
    });

    // 这个to.path要写
    // 如果直接next()，路由还没有完整，还是个空的，它不确定里面有没有这个路径
    // 加了to.path之后，会重新走一遍路由守卫，此时路由添加完毕，可以检查出用户能不能访问这个路径
    next(to.path);
    return
  }

  next()
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location){
  return originalPush.call(this,location).catch(()=>{})
};

export default router
